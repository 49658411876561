import React from 'react';
import Slider from "react-slick";
import teamMember_01 from "../assets/images/team/1.png";
import teamMember_02 from "../assets/images/team/2.png";
import teamMember_03 from "../assets/images/team/3.png";
import icon_01 from "../assets/images/team/icon/1.png";
import icon_02 from "../assets/images/team/icon/2.png";
import icon_03 from "../assets/images/team/icon/3.png";

const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
        {
            breakpoint: 1201,
            settings: {
                slidesToShow: 3,
            }
        },
        {
            breakpoint: 992,
            settings: {
                slidesToShow: 2,
            }
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 1,
            }
        },
        {
            breakpoint: 576,
            settings: {
                slidesToShow: 1,
            }
        }
    ]
};

export default function Our_team() {
    return (
        <section className="team-area pt-140 pb-140" id="team">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 offset-lg-3 text-center pb-80">
                        <div className="section-title team-title">
                            <p className="section-para"><span></span>our team member</p>
                            <h1>Meet Our Most Creative Minds That’s Are Professional</h1>
                        </div>
                    </div>
                </div>
                <div className="row team-carousel-active carousel-one">
                    <div className="col-md-12"> 
                    <Slider {...settings}>
                        <div className="single-team-area p-3">
                            <div className="single-team-img text-center">
                                <img className="img-fluid" src={teamMember_01} alt="team one" />
                                <span className="team-icon-1"><img src={icon_01} alt="icon one" /></span>
                                <span className="team-icon-2"><img src={icon_02} alt="icon two" /></span>
                                <span className="team-icon-3"><img src={icon_03} alt="icon three" /></span>
                            </div>
                            <div className="single-team-content text-center">
                                <h4>Joseph M. Fondren</h4>
                                <p>Apps deigner</p>
                            </div>
                        </div>
                        <div className="single-team-area p-3">
                            <div className="single-team-img text-center">
                                <img className="img-fluid" src={teamMember_02} alt="team one" />
                                <span className="team-icon-1"><img src={icon_01} alt="icon one" /></span>
                                <span className="team-icon-2"><img src={icon_02} alt="icon two" /></span>
                                <span className="team-icon-3"><img src={icon_03} alt="icon three" /></span>
                            </div>
                            <div className="single-team-content text-center">
                                <h4>Joseph M. Fondren</h4>
                                <p>Apps deigner</p>
                            </div>
                        </div>
                        <div className="single-team-area p-3">
                            <div className="single-team-img text-center">
                                <img className="img-fluid" src={teamMember_03} alt="team one" />
                                <span className="team-icon-1"><img src={icon_01} alt="icon one" /></span>
                                <span className="team-icon-2"><img src={icon_02} alt="icon two" /></span>
                                <span className="team-icon-3"><img src={icon_03} alt="icon three" /></span>
                            </div>
                            <div className="single-team-content text-center">
                                <h4>Joseph M. Fondren</h4>
                                <p>Apps deigner</p>
                            </div>
                        </div>
                        <div className="single-team-area p-3">
                            <div className="single-team-img text-center">
                                <img className="img-fluid" src={teamMember_03} alt="team one" />
                                <span className="team-icon-1"><img src={icon_01} alt="icon one" /></span>
                                <span className="team-icon-2"><img src={icon_02} alt="icon two" /></span>
                                <span className="team-icon-3"><img src={icon_03} alt="icon three" /></span>
                            </div>
                            <div className="single-team-content text-center">
                                <h4>Joseph M. Fondren</h4>
                                <p>Apps deigner</p>
                            </div>
                        </div>
                    </Slider>
                    </div>
                </div>
            </div>
        </section>
    )
}
