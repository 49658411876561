import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import SimpleReactLightbox from "simple-react-lightbox";
import Header from "./Components/header";
import HeroArea from "./Components/hero_area";
import Features from "./Components/features";
import AboutUs from "./Components/about_us";
import Services from "./Components/services";
import Portfolios from "./Components/portfolios";
import CallToAction from "./Components/call_to_action";
import OurTeam from "./Components/our_team";
import Video from "./Components/video";
import Countdown from "./Components/countdown";
import Testimonials from "./Components/testimonials";
import Pricing from "./Components/pricing";
import Blogs from "./Components/blogs";
import Footer from "./Components/footer";
function App() {
  return (
    <div className="App">
      <Header/>
      <HeroArea/>
      <Features/>
      <AboutUs/>
      <Services/>
      <SimpleReactLightbox>
        <Portfolios />
      </SimpleReactLightbox>
      <CallToAction/>
      <OurTeam/>
      <Video/>
      <Countdown/>
      <Testimonials/>
      <Pricing/>
      <Blogs/>
      <Footer/>
    </div>
  );
}

export default App;
