import React from "react";
import { FaCheck } from "react-icons/fa";
import { FaArrowRight } from "react-icons/fa";
import AboutUsImg_01 from "../assets/images/about-us/1.png";
import AboutUsImg_02 from "../assets/images/about-us/2.png";

export default function about_us() {
    return (
        <section className="about-us-area" id="About">
            <div className="container">
                <div className="row">
                    <div className="about-us-img">
                        <img src={AboutUsImg_01} alt="about" className="about-img-1" />
                        <img src={AboutUsImg_02} alt="" className="about-img-2" />
                    </div>
                    <div className="col-lg-5 offset-lg-7">
                        <div className="about-us-content">
                            <div className="section-title about-us-title">
                                <p className="section-para"> <span></span>About Us </p>
                                <h1>Most Creative Agency For Your Project</h1>
                            </div>
                            <div className="about-us-text">
                                <span>A</span>
                                <p> Avoids pleasure itself, because it is pleasure, but because
                                    those who do not know how to pursuetw pleasure ration counter
                                    consequences that ares extremely painful. Nor again is tanyone
                                    who loves or pursues or desires to obtain pain </p>
                            </div>
                            <ul className="about-menu">
                                <li>
                                    <a href="#"> <i><FaCheck /></i> Web Design </a>
                                </li>
                                <li>
                                    <a href="#"> <i><FaCheck /></i> Web Development </a>
                                </li>
                                <li>
                                    <a href="#"> <i><FaCheck /></i> Graphics Arts </a>
                                </li>
                                <li>
                                    <a href="#"> <i><FaCheck /></i> Product Marketing </a>
                                </li>
                                <li>
                                    <a href="#"> <i><FaCheck /></i> User Research </a>
                                </li>
                            </ul>
                            <a href="#" className="custom-btn about-btn">
                                Contact Us <i><FaArrowRight /></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
