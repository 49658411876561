import React from 'react';
import { FaArrowRight } from "react-icons/fa";
import { FaCalendarAlt } from "react-icons/fa";
import blogImg_01 from "../assets/images/blog/05.jpg";
import blogImg_02 from "../assets/images/blog/06.jpg";
import blogImg_03 from "../assets/images/blog/07.jpg";

export default function Blogs() {
    return (
        <section className="blog-area pb-140" id="Blog">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 pb-80 pb-xs-0">
                        <div className="section-title blog-title">
                            <p className="section-para"><span></span>News & blog</p>
                            <h1>Latest Articles For New Project</h1>
                        </div>
                    </div>
                    <div className="col-lg-8 text-right">
                        <a href="#" className="custom-btn blog-btn mb-xs-5">view all news <i><FaArrowRight /></i></a>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-4">
                        <div className="single-blog-area">
                            <div className="single-blog-img">
                                <a href="#"><img src={blogImg_01} alt="blog" /></a>
                            </div>
                            <div className="single-blog-content">
                                <p><i><FaCalendarAlt /></i> 25 Dec 2019</p>
                                <p className="styled-para">Inspiring Desktop Wallpap Makes November Even More Colorful (2019 Edition)</p>
                                <a href="#" className="blog-read-more">Raed More <i><FaArrowRight /></i></a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="single-blog-area">
                            <div className="single-blog-img">
                                <a href="#"><img src={blogImg_02} alt="blog" /></a>
                            </div>
                            <div className="single-blog-content">
                                <p><i><FaCalendarAlt /></i> 25 Dec 2019</p>
                                <p className="styled-para">We’ve Got A Lil’ Announ To Make Rachel Andrew Is Smas HingMag’s New Editor</p>
                                <a href="#" className="blog-read-more">Raed More <i> <FaArrowRight /></i></a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="single-blog-area">
                            <div className="single-blog-img">
                                <a href="#"><img src={blogImg_03} alt="blog" /></a>
                            </div>
                            <div className="single-blog-content">
                                <p><i><FaCalendarAlt /></i> 25 Dec 2019</p>
                                <p className="styled-para">Quick Wins For Improving Performance And Security Of Your Website</p>
                                <a href="#" className="blog-read-more">Raed More <i><FaArrowRight /></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
