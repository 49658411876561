import React, {useState} from 'react';
import { FaArrowRight } from "react-icons/fa";
import { FaPlay } from "react-icons/fa";
import videoBgImg from "../assets/images/video-bg.jpg";
import videoTextImg from "../assets/images/video-text.png";
import { Modal } from "react-bootstrap";

export default function Video() {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <section className="video-area vdeo-bg" style={{ backgroundImage: `url(${videoBgImg})`}}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="video-icon-area">
                            <a href="#" className="video-icon video-popup" onClick={handleShow}><i><FaPlay /></i></a>
                        </div>
                        <Modal show={show} onHide={handleClose}>
                            <Modal.Header closeButton>
                                <Modal.Title>Video Popup</Modal.Title>
                            </Modal.Header>
                            <Modal.Body className="p-0">
                                <div className="embed-responsive embed-responsive-4by3">
                                    <iframe title="video" className="embed-responsive-item" src="https://www.youtube.com/embed/iogabydg2y0" allowFullScreen></iframe>
                                </div>
                            </Modal.Body>
                        </Modal>
                    </div>
                </div>
            </div>
            <div className="video-content">
                <span><img src={videoTextImg} alt="video"/></span>
                <h1>Watch Our Latest Videos</h1>
                <p>Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the</p>
                <a href="#" className="video-read-more">Learn More <i><FaArrowRight /></i></a>
            </div>
        </section>
    )
}
