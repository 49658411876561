import React from 'react';
import Slider from "react-slick";
import testimonialImage from "../assets/images/testimonial/1.png";
import authorImage from "../assets/images/testimonial/author.png";
import { FaQuoteRight } from "react-icons/fa";
const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false
};
export default function Testimonials() {
    return (
        <section className="testimonial-area carousel-one" id="testimonial">
            <div className="container">
                <div className="row">
                    <div className="col-lg-5">
                        <img src={testimonialImage} className="img-fluid" alt="testimonials"/>
                    </div>
                    <div className="col-lg-7 textimonial-carousel-active">
                        <Slider {...settings}>
                            <div className="single-testimonial-content">
                                <span className="testimonial-quote"><FaQuoteRight /></span>
                                <p>Avoids pleasur itself because pleas because those who do not know how to pursue plesure rationally encou eque that are extrem painful again is there anyone who loves pursues or desires to obtain pain of itself.</p>
                                <div className="autohor-details">
                                    <img src={authorImage} alt="author" className="author-img" />
                                    <h5 className="author-name">Michele L. Racinea <span>Web Designer</span></h5>
                                </div>
                            </div>
                            <div className="single-testimonial-content">
                                <span className="testimonial-quote"><FaQuoteRight /></span>
                                <p>Avoids pleasur itself because pleas because those who do not know how to pursue plesure rationally encou eque that are extrem painful again is there anyone who loves pursues or desires to obtain pain of itself.</p>
                                <div className="autohor-details">
                                    <img src={authorImage} alt="author" className="author-img" />
                                    <h5 className="author-name">Michele L. Racinea <span>Web Designer</span></h5>
                                </div>
                            </div>
                            <div className="single-testimonial-content">
                                <span className="testimonial-quote"><FaQuoteRight /></span>
                                <p>Avoids pleasur itself because pleas because those who do not know how to pursue plesure rationally encou eque that are extrem painful again is there anyone who loves pursues or desires to obtain pain of itself.</p>
                                <div className="autohor-details">
                                    <img src={authorImage} alt="author" className="author-img" />
                                    <h5 className="author-name">Michele L. Racinea <span>Web Designer</span></h5>
                                </div>
                            </div>
                        </Slider>
                    </div>
                </div>
            </div>
        </section>
    )
}
