import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import HeroImg_01 from "../assets/images/hero/01.jpg";
import HeroImg_02 from "../assets/images/hero/02.jpg";
import HeroImg_03 from "../assets/images/hero/03.jpg";
import { FaArrowRight } from "react-icons/fa";
import { FaPlay } from "react-icons/fa";
const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
};

export default function Hero_area() {
    const [show_01, setShow_01] = useState(false);
    const [show_02, setShow_02] = useState(false);
    const [show_03, setShow_03] = useState(false);

    const handleShow_01 = () => setShow_01(true);
    const handleShow_02 = () => setShow_02(true);
    const handleShow_03 = () => setShow_03(true);
    const handleClose_01 = () => setShow_01(false);
    const handleClose_02 = () => setShow_02(false);
    const handleClose_03 = () => setShow_03(false);

    return (
        <section className="hero-area hero-carousel-active" id="home">
            <Slider {...settings}>
                <div className="single-hero-wrapper">
                    <div
                        className="single-hero-area hero-bg-1 bg_cover"
                        style={{ backgroundImage: `url(${HeroImg_01})` }}
                        data-scroll-area="true">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-7">
                                    <div className="hero-area-content">
                                        <p className="hero-para">
                                            Start New Business <br /> With Our New Experience and</p>
                                        <h1 className="hero-heading">Creative Minds</h1>
                                        <div className="hero-area-menu">
                                            <ul>
                                                <li>
                                                    <a href="#">Design</a>
                                                </li>
                                                <li>
                                                    <a href="#">Marketing</a>
                                                </li>
                                                <li>
                                                    <a href="#">Developemt</a>
                                                </li>
                                            </ul>
                                        </div>
                                        <a href="#" className="custom-btn hero-btn">get Started <i> <FaArrowRight /></i></a>
                                        <a href="#" onClick={handleShow_01} className="video-btn video-popup">
                                             <i> <FaPlay /></i>
                                        </a>
                                        <Modal
                                            show={show_01}
                                            onHide={handleClose_01}
                                            size="md"
                                            centered >
                                            <Modal.Header closeButton>
                                                <Modal.Title>Watch Video</Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body className="p-0">
                                                <div className="embed-responsive embed-responsive-4by3">
                                                    <iframe
                                                        className="embed-responsive-item"
                                                        src="https://www.youtube.com/embed/XIhEPwTMjWk"
                                                        title="video one"
                                                        allowFullScreen
                                                    ></iframe>
                                                </div>
                                            </Modal.Body>
                                        </Modal>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="single-hero-wrapper">
                    <div className="single-hero-area hero-bg-1 bg_cover" style={{ backgroundImage: `url(${HeroImg_02})` }}data-scroll-area="true">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-7">
                                    <div className="hero-area-content">
                                        <p className="hero-para">
                                            Start New Business <br /> With Our New Experience and</p>
                                        <h1 className="hero-heading">Creative Minds</h1>
                                        <div className="hero-area-menu">
                                            <ul>
                                                <li>
                                                    <a href="#">Design</a>
                                                </li>
                                                <li>
                                                    <a href="#">Marketing</a>
                                                </li>
                                                <li>
                                                    <a href="#">Developemt</a>
                                                </li>
                                            </ul>
                                        </div>
                                        <a href="#" className="custom-btn hero-btn"> get Started <i> <FaArrowRight /></i></a>
                                        <a href="#" onClick={handleShow_02} className="video-btn video-popup">
                                            <i>
                                                <FaPlay />
                                            </i>
                                        </a>
                                        <Modal
                                            show={show_02}
                                            onHide={handleClose_02}
                                            size="md"
                                            centered>
                                            <Modal.Header closeButton>
                                                <Modal.Title>Watch Video Two</Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body className="p-0">
                                                <div className="embed-responsive embed-responsive-4by3">
                                                    <iframe
                                                        className="embed-responsive-item"
                                                        src="https://www.youtube.com/embed/PZDT371T06I"
                                                        title= "video two"
                                                        allowFullScreen
                                                    ></iframe>
                                                </div>
                                            </Modal.Body>
                                        </Modal>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="single-hero-wrapper">
                    <div className="single-hero-area hero-bg-1 bg_cover" style={{ backgroundImage: `url(${HeroImg_03})` }}data-scroll-area="true">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-7">
                                    <div className="hero-area-content">
                                        <p className="hero-para">
                                            Start New Business <br /> With Our New Experience and</p>
                                        <h1 className="hero-heading">Creative Minds</h1>
                                        <div className="hero-area-menu">
                                            <ul>
                                                <li>
                                                    <a href="#">Design</a>
                                                </li>
                                                <li>
                                                    <a href="#">Marketing</a>
                                                </li>
                                                <li>
                                                    <a href="#">Developemt</a>
                                                </li>
                                            </ul>
                                        </div>
                                        <a href="#" className="custom-btn hero-btn"> get Started <i> <FaArrowRight /></i></a>
                                        <a href="#" onClick={handleShow_03} className="video-btn video-popup">
                                            <i>
                                                <FaPlay />
                                            </i>
                                        </a>
                                        <Modal
                                            show={show_03}
                                            onHide={handleClose_03}
                                            size="md"
                                            centered>
                                            <Modal.Header closeButton>
                                                <Modal.Title>Watch Video Three</Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body className="p-0">
                                                <div className="embed-responsive embed-responsive-4by3">
                                                    <iframe
                                                        className="embed-responsive-item"
                                                        src="https://www.youtube.com/embed/zpOULjyy-n8?rel=0"
                                                        title="title three"
                                                        allowFullScreen
                                                    ></iframe>
                                                </div>
                                            </Modal.Body>
                                        </Modal>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Slider>
        </section>
    );
}
