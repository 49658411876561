import React from 'react';
import CountUp from 'react-countup';
import VisibilitySensor from "react-visibility-sensor";

export default function single_countdown(props) {
    const { icon, start, end, duration, delay, text } = props;
    return (
      <>
        <div className="counter-icon">
          {icon}
        </div>
        <div className="counter-text">
            <VisibilitySensor>
                {({ isVisible }) =>
                        <h1 className="count"> {isVisible ? <CountUp start={start} end={end} duration={duration} delay={delay} /> :
                        "0"}</h1>
                }
            </VisibilitySensor>
            <p>{text}</p>
        </div>
      </>
    )
}
