import React from 'react';
import { SRLWrapper } from "simple-react-lightbox";
import { FaArrowRight } from "react-icons/fa";
import PortfolioImg_01 from "../assets/images/portfolio/1.jpg";
import PortfolioImg_02 from "../assets/images/portfolio/2.jpg";
import PortfolioImg_03 from "../assets/images/portfolio/3.jpg";
import PortfolioImg_04 from "../assets/images/portfolio/4.jpg";
import PortfolioImg_05 from "../assets/images/portfolio/5.jpg";

export default function Portfolios() {
    return (
        <section className="portfolio-area pt-140 pb-140" id="portfolio">
            <div className="container">
                <div className="row pb-80">
                    <div className="col-lg-5">
                        <div className="section-title portfolio-title">
                            <p className="section-para"><span></span> Our Portfolios</p>
                            <h1>Most Creative Agency For Your Project</h1>
                        </div>
                    </div>
                    <div className="col-lg-7 text-right">
                        <div className="portfolio-btn-area">
                            <a href="#" className="custom-btn portfolio-btn">View more <i><FaArrowRight /></i></a>
                            <a href="#" className="portfolio-btn-2">Let’s Talk <i><FaArrowRight /></i></a>
                        </div>
                    </div>
                </div>
            </div>
            <SRLWrapper> 
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-4 col-md-4">
                        <div className="single-portfolio-item">
                            <a href={PortfolioImg_01} className="img-popup" data-attribute="SRL"><img src={PortfolioImg_01} alt="portfolio" /></a>
                            <div className="portfolio-overlay-content">
                                <h2>Creative Works</h2>
                                <p>Light Illustration</p>
                                <a href="#" className="portfolio-icon img-popup"> <i><FaArrowRight /></i></a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8 col-md-8">
                        <div className="row">
                            <div className="col-lg-6 pb-30 col-md-6">
                                <div className="single-portfolio-item">
                                     <a href={PortfolioImg_02} className="img-popup" data-attribute="SRL"><img src={PortfolioImg_02} alt="portfolio" /></a>
                                    <div className="portfolio-overlay-content">
                                        <h2>Creative Works</h2>
                                        <p>Light Illustration</p>
                                        <a href="#" className="portfolio-icon img-popup"> <FaArrowRight /></a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 pb-30 col-md-6">
                                <div className="single-portfolio-item">
                                    <a href={PortfolioImg_03} className="img-popup" data-attribute="SRL"><img src={PortfolioImg_03} alt="portfolio" /></a>
                                    <div className="portfolio-overlay-content">
                                        <h2>Creative Works</h2>
                                        <p>Light Illustration</p>
                                        <a href="#" className="portfolio-icon img-popup"> <FaArrowRight /></a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <div className="single-portfolio-item">
                                    <a href={PortfolioImg_04} className="img-popup" data-attribute="SRL"><img src={PortfolioImg_04} alt="portfolio" /></a>
                                    <div className="portfolio-overlay-content">
                                        <h2>Creative Works</h2>
                                        <p>Light Illustration</p>
                                        <a href="#" className="portfolio-icon img-popup"> <FaArrowRight /></a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <div className="single-portfolio-item">
                                    <a href={PortfolioImg_05} className="img-popup" data-attribute="SRL"><img src={PortfolioImg_05} alt="portfolio" /></a>
                                    <div className="portfolio-overlay-content">
                                        <h2>Creative Works</h2>
                                        <p>Light Illustration</p>
                                        <a href="#" className="portfolio-icon img-popup"> <FaArrowRight /></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </SRLWrapper>
        </section>
    )
}
