import React from 'react';
import { FaArrowRight } from "react-icons/fa";
import callToActionImg from "../assets/images/cta-text.png";
import callToActionBg from "../assets/images/cta-bg.jpg";
export default function Call_to_action() {
    return (
        <section className="cta-area cta-bg pt-140 pb-140" style={{ backgroundImage: `url(${callToActionBg})` }}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="cta-title">
                            <span><img src={callToActionImg} alt="call to action"/></span>
                            <h1>Have Any Creative Project</h1>
                            <p>We denounce with righteous indignation and dislike men who are so beguiled and demoralized by the charms of pleasure</p>
                            <a href="#" className="custom-btn cta-btn">meet with us <i><FaArrowRight /></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
