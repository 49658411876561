import React from "react";
import FeatureIcon_01 from "../assets/images/service-icon/1.png";
import FeatureIcon_02 from "../assets/images/service-icon/2.png";
import FeatureIcon_03 from "../assets/images/service-icon/3.png";
export default function features() {
    return (
        <section className="service-area service-categories pt-100 pb-60">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4">
                        <div className="single-service-area">
                            <span className="service-num">01</span>
                        </div>
                        <div className="service-icon">
                            <img src={FeatureIcon_01} alt="feature one" />
                        </div>
                        <div className="service-content">
                            <h4>Product Design</h4>
                            <p> It is pleasure, but because those who do not know how pursue pleasure ration</p>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="single-service-area">
                            <span className="service-num">02</span>
                        </div>
                        <div className="service-icon">
                            <img src={FeatureIcon_02} alt="feature two" />
                        </div>
                        <div className="service-content">
                            <h4>UX/UI Strategy</h4>
                            <p> Pain can procure hi some great pleasutrivial example, which ever undertakes</p>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="single-service-area">
                            <span className="service-num">03</span>
                        </div>
                        <div className="service-icon">
                            <img src={FeatureIcon_03} alt="feature three" />
                        </div>
                        <div className="service-content">
                            <h4>Market Analysis</h4>
                            <p> Some advantage from but who has any right tfind fault with a man chooses</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
