import React from 'react';
import priceIcon from "../assets/images/price-icon.png";
import { FaArrowRight } from "react-icons/fa";
export default function Pricing() {
    return (
        <section className="pricing-area pt-140 pb-140" id="price">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 offset-lg-3 text-center">
                        <div className="section-title pricing-title pb-80">
                            <p className="section-para"><span></span>our pricing</p>
                            <h1>Popular Pricing Plan For Creative Project</h1>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-4">
                        <div className="single-price-box">
                            <div className="absolute-price-num">
                                <h2>01 <span>basic</span></h2>
                            </div>
                            <img src={priceIcon} alt="pricing" className="price-icon"/>
                            <h3><span>$45.99</span> For Monthly Project Charge</h3>
                            <p><i><FaArrowRight/></i> Web Design (UX/UI)</p>
                            <p><i><FaArrowRight/></i> Software Development</p>
                            <p><i><FaArrowRight/></i> Fashion Design (Arts)</p>
                            <p><i><FaArrowRight/></i> Web Development (php)</p>
                            <div className="hover-pricing-text">
                                <p><i><FaArrowRight/></i> Game Design & DV</p>
                                <p><i><FaArrowRight/></i> Computer Hardware</p>
                            </div>
                            <a href="#" className="custom-btn price-btn">make plan <i><FaArrowRight /></i></a>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="single-price-box active">
                            <div className="absolute-price-num">
                                <h2>02 <span>smart</span></h2>
                            </div>
                            <img src={priceIcon} alt="pricing" className="price-icon" />
                            <h3><span>$65.99</span> For Monthly Project Charge</h3>
                            <p><i><FaArrowRight/></i> Web Design (UX/UI)</p>
                            <p><i><FaArrowRight/></i> Software Development</p>
                            <p><i><FaArrowRight/></i> Fashion Design (Arts)</p>
                            <p><i><FaArrowRight/></i> Web Development (php)</p>
                            <div className="hover-pricing-text">
                                <p><i><FaArrowRight/></i> Game Design & DV</p>
                                <p><i><FaArrowRight/></i> Computer Hardware</p>
                            </div>
                            <a href="#" className="custom-btn price-btn">make plan <i><FaArrowRight /></i></a>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="single-price-box">
                            <div className="absolute-price-num">
                                <h2>03 <span>Premium</span></h2>
                            </div>
                            <img src={priceIcon} alt="pricing" className="price-icon" />
                            <h3><span>$103.99</span> For Monthly Project Charge</h3>
                            <p><i><FaArrowRight /></i> Web Design (UX/UI)</p>
                            <p><i><FaArrowRight /></i> Software Development</p>
                            <p><i><FaArrowRight /></i> Fashion Design (Arts)</p>
                            <p><i><FaArrowRight /></i> Web Development (php)</p>
                            <div className="hover-pricing-text">
                                <p><i><FaArrowRight /></i> Game Design & DV</p>
                                <p><i><FaArrowRight /></i> Computer Hardware</p>
                            </div>
                            <a href="#" className="custom-btn price-btn">make plan <i><FaArrowRight /></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )
}
