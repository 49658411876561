import React from 'react';
import { FaArrowRight } from "react-icons/fa";
import { FaRocket } from "react-icons/fa";
import { FaLifeRing } from "react-icons/fa";
import { FaTrophy } from "react-icons/fa";
import { FaUniversalAccess } from "react-icons/fa";
import SingleCountdown from "./Features/single_countdown";
export default function Countdown() {
    return (
        <section className="project-counter-area">
            <div className="container">
                <div className="row pb-80">
                    <div className="col-lg-6">
                        <div className="section-title project-title">
                            <p className="section-para"><span></span>company fact</p>
                            <h1>We Are Digital And Creative Agency</h1>
                        </div>
                    </div>
                    <div className="col-lg-6 text-right">
                        <a href="#" className="custom-btn counter-btn">get started <i><FaArrowRight /></i></a>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-3 col-md-6">
                        <SingleCountdown
                         icon={<FaRocket />}
                         start={0}
                         end={6320}
                         duration={5}
                         delay={.3}
                         text="Project complated"
                        />
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <SingleCountdown
                         icon={<FaLifeRing />}
                         start={0}
                         end={950}
                         duration={5}
                         delay={.4}
                         text="Satisfied Clients"
                        />
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <SingleCountdown
                         icon={<FaTrophy />}
                         start={0}
                         end={650}
                         duration={5}
                         delay={.4}
                         text="Pwins awards"
                        />
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <SingleCountdown 
                         icon = {<FaUniversalAccess/>}
                         start = {0}
                         end = {4520}
                         duration = {5}
                         delay = {.4}
                         text= "Expert Team Member"
                        />
                    </div>
                </div>
            </div>
        </section>
    )
}
