import React, {useState, useEffect} from 'react';
import { Link, animateScroll as scroll } from 'react-scroll';
import Logo from "../assets/images/logo.png";
import { FaPhone } from "react-icons/fa";
import { FaMapMarkerAlt } from "react-icons/fa";
import { FaClock } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaBars } from "react-icons/fa";
import { Navbar } from 'react-bootstrap';

const goHome = (e) => {
    e.preventDefault();
    window.scrollTo({ top: 0, behavior: 'smooth' });
}

export default function Header() {

    const [stickyMenu, setStickyMenu] = useState(false);
    const [openNavMenu,setOpenNavMenu] = useState(false);
    const controlStickyMenu = () => {
        if (window.scrollY > 150) {
            setStickyMenu(true);

        } else if (window.scrollY < 150) {
            setStickyMenu(false);
        }
    }

    const controlMobileMenu = () => {
        setOpenNavMenu(false);
    }

    const toggleMenu = () => {
        setOpenNavMenu(!openNavMenu);
    }

    useEffect(() => {
        window.addEventListener("scroll", controlStickyMenu);
    }, [stickyMenu]);

    return (
        <div>
            <header>
                <div className="top-header-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-9">
                                <div className="row">
                                    <div className="col-lg-3">
                                        <div className="top-header-content">
                                            <span><FaPhone/> +012 (345) 67899</span>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="top-header-content">
                                            <span><FaMapMarkerAlt/> 63 Plabon Road, USA</span>
                                        </div>
                                    </div>
                                    <div className="col-lg-5">
                                        <div className="top-header-content">
                                            <span><FaClock/> Sun - Friday : 10 am - 08 pm</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 text-right border">
                                <div className="top-header-social-links">
                                    <ul>
                                        <li><a href="#"><FaFacebookF/></a></li>
                                        <li><a href="#"><FaTwitter/></a></li>
                                        <li><a href="#"><FaInstagram/></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={stickyMenu ? "bottom-header-area sticky" : "bottom-header-area"}>
                    <div className="container">
                        <div className="row">
                                <div className="col-lg-12">
                                <div className="main-menu"> 
                                    <Navbar collapseOnSelect expanded={openNavMenu} expand="lg" onToggle={()=>toggleMenu()}>
                                    <a className="navbar-brand" href={process.env.PUBLIC_URL}><img src={Logo} alt="logo" /></a>
                                        <Navbar.Toggle aria-controls="responsive-navbar-nav"> 
                                          <span className="text-white"><FaBars/> </span> 
                                        </Navbar.Toggle>
                                    
                                        <Navbar.Collapse className="text-white" id="responsive-navbar-nav">
                                        <ul className="navbar-nav ml-auto">
                                            <li>
                                                <Link className="nav-link" to="home" smooth={true} duration={2000} activeClass="current" spy={true} onClick={goHome}> Home </Link>
                                            </li>
                                            <li>
                                                <Link className="nav-link" to="About" smooth={true} duration={2000} activeClass="current" spy={true} offset={-70} onClick={() => controlMobileMenu()}> About </Link>
                                            </li>
                                            <li>
                                                <Link className="nav-link" to="Services" smooth={true} duration={2000} activeClass="current" spy={true} offset={-70} onClick={() => controlMobileMenu()}> Services </Link>
                                            </li>
                                            <li>
                                                <Link className="nav-link" to="portfolio" smooth={true} duration={2000} activeClass="current" spy={true} offset={-70} onClick={() => controlMobileMenu()}> Portfolio </Link>
                                            </li>
                                            <li>
                                                <Link className="nav-link" to="team" smooth={true} duration={2000} activeClass="current" spy={true} offset={-70} onClick={() => controlMobileMenu()}> Team </Link>
                                            </li>
                                            <li>
                                                <Link className="nav-link" to="price" smooth={true} duration={2000} activeClass="current" spy={true} offset={-70} onClick={() => controlMobileMenu()}> Price </Link>
                                            </li>
                                            <li>
                                                <Link className="nav-link" to="Blog" smooth={true} duration={2000} activeClass="current" spy={true} offset={-70} onClick={() => controlMobileMenu()}> Blog </Link>
                                            </li>
                                            <li>
                                                <Link className="nav-link" to="Contact" smooth={true} duration={2000} activeClass="current" spy={true} offset={-70} onClick={() => controlMobileMenu()}> Contact </Link>
                                            </li>
                                        </ul>
                                    </Navbar.Collapse>
                                </Navbar>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
             </header>
        </div>
    );
}
