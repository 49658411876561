import React from 'react';
import { Formik, Field, Form } from "formik";
import contactImg from "../assets/images/contact-text.png";
import footerBgImg from "../assets/images/footer-bg.jpg";
import { FaUserAlt } from "react-icons/fa";
import { FaEnvelopeOpenText } from "react-icons/fa";
import { FaPencilAlt } from "react-icons/fa";
import { FaPhone } from "react-icons/fa";
import { FaArrowRight } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaBehance } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaArrowUp } from "react-icons/fa";

const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth'});
}
export default function Footer() {
    return (
        <footer>
            <div className="contact-with-footer-area contact-bg pt-100" id="Contact" style={{ backgroundImage: `url(${footerBgImg})`}}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 offset-lg-3 text-center pb-80">
                            <div className="section-title contact-title">
                                <span><img src={contactImg} alt="contact"/></span>
                                <p className="section-para"><span></span>Message Us</p>
                                <h1>Don't Hesitate To Contact With Us</h1>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="contact-form-area">
                                <Formik
                                    initialValues={{ user_name: "", user_email: "", user_phone: "", user_message: "" }}
                                    onSubmit={async values => {
                                        await new Promise(resolve => setTimeout(resolve, 500));
                                        console.log(values);
                                    }}>
                                    <Form>
                                        <div className="single-contact-field">
                                            <Field name="user_name" type="text" placeholder="your name" required/>
                                            <span><FaUserAlt /></span>
                                        </div>
                                        <div className="single-contact-field">
                                            <Field name="user_email" type="email" placeholder="your email" required/>
                                            <span><FaEnvelopeOpenText /></span>
                                        </div>
                                        <div className="single-contact-field">
                                            <Field name="user_phone" type="phone" placeholder="your phone" required/>
                                            <span><FaPhone /></span>
                                        </div>
                                        <div className="single-contact-field textarea">
                                            <Field name="user_message" type="text" as="textarea" placeholder="your message" required/>
                                            <span><FaPencilAlt /></span>
                                            <button type="submit" className="custom-btn contact-btn">send message <FaArrowRight /></button>
                                        </div>
                                    </Form>
                                </Formik>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="single-address-area">
                                <span><i><FaArrowRight/></i> +012 (345) 77 999</span>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="single-address-area">
                                <span><i><FaArrowRight/></i> support@gmail.com</span>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="single-address-area">
                                <span><i><FaArrowRight/></i> 55 New Sodor Road, USA</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-bottom-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <p>&copy; 2020 Livvic. All Rights Reserved</p>
                            </div>
                            <div className="col-lg-6">
                                <ul className="footer-links">
                                    <li>
                                        <a href="#"><i><FaFacebookF/></i></a>
                                    </li>
                                    <li>
                                        <a href="#"><i><FaTwitter/></i></a>
                                    </li>
                                    <li>
                                        <a href="#"><i><FaBehance/></i></a>
                                    </li>
                                    <li>
                                        <a href="#"><i><FaInstagram/></i></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="scroll-to-top" onClick={scrollToTop}>
                <span id="return-to-top"><i><FaArrowUp /></i></span>
            </div>
        </footer>            
    )
}
