import React from 'react';
import ServiceTextImage from "../assets/images/service-text.png";
import ServiceBgImg from "../assets/images/service.jpg"
import { FaArrowRight } from "react-icons/fa";
import { FaLink } from "react-icons/fa";
import { FaChartLine } from "react-icons/fa";
import { FaBezierCurve } from "react-icons/fa";
import { FaLaptopCode } from "react-icons/fa";
export default function Services() {
    return (
        <section className="service-area service-bg pt-140 pb-140" id="Services" style={{ backgroundImage: `url(${ServiceBgImg})`}}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 offset-lg-3 text-center pb-80">
                        <div className="section-title service-title">
                            <span><img src={ServiceTextImage} alt="services"/></span>
                            <p className="section-para"><span></span>Our Services</p>
                            <h1>Most Creative Agency For Your Project</h1>
                        </div>
                    </div>
                </div>
                <div className="row pb-30">
                    <div className="col-lg-6">
                        <div className="single-service-box">
                            <div className="single-service-icon-box">
                                <p>01</p>
                                <i><FaBezierCurve /></i>
                            </div>
                            <div className="service-box-content">
                                <h3>Graphics Design</h3>
                                <p>Inventore veritatis et quasi beata vitae dicta sunt explicabo. Neenms ipsam voluptatem voluptas</p>
                                <a href="#" className="service-btn">Raed More <i><FaArrowRight /></i></a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="single-service-box">
                            <div className="single-service-icon-box">
                                <p>02</p>
                                <i><FaLaptopCode /></i>
                            </div>
                            <div className="service-box-content">
                                <h3>Web Design</h3>
                                <p>Inventore veritatis et quasi beata vitae dicta sunt explicabo. Neenms ipsam voluptatem voluptas</p>
                                <a href="#" className="service-btn">Raed More <i><FaArrowRight /></i></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6">
                        <div className="single-service-box">
                            <div className="single-service-icon-box">
                                <p>03</p>
                                <i><FaChartLine/></i>
                            </div>
                            <div className="service-box-content">
                                <h3>Data Analysis</h3>
                                <p>Inventore veritatis et quasi beata vitae dicta sunt explicabo. Neenms ipsam voluptatem voluptas</p>
                                <a href="#" className="service-btn">Raed More <i><FaArrowRight /></i></a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="single-service-box">
                            <div className="single-service-icon-box">
                                <p>04</p>
                                <i><FaLink/></i>
                            </div>
                            <div className="service-box-content">
                                <h3>Server Security</h3>
                                <p>Inventore veritatis et quasi beata vitae dicta sunt explicabo. Neenms ipsam voluptatem voluptas</p>
                                <a href="#" className="service-btn">Raed More <i><FaArrowRight /></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )
}
